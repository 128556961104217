import React from "react";
import Header from "./components/Header.js";
import Text from "./components/Text.js";
import Carousel from "./components/Carousel.js";
import Featured from "./components/Featured.js";
import Tabs from "./components/Tabs.js";
import Contact from "./components/Contact.js";
import Floorplan from "./components/Floorplan.js";

export default function App() {
  return (
    <div>
      <Header />
      <Text />
      <Featured />
      <Carousel />
      <Tabs />
      <Floorplan />
      <Contact />
    </div>
  );
}
