export default function Floorplan() {
  return (
    <div className="mx-auto md:max-w-5xl px-8 md:px-0 md:py-32 py-12 md:grid grid-cols-2 gap-x-20 space-y-10 md:space-y-0">
      <div className="">
        <img src="/floorplan.jpg" alt="image" className="object-cover" />
      </div>
      <div className="flex flex-col justify-center">
        <h3 className="font-medium font-bricolage tracking-wide uppercase mb-4">
          Floorplan
        </h3>
        <p className="font-bricolage">
          This 4-bedroom villa spans over 482m2, creating the perfect home for
          families. The property opens up on a Meditterranean-inspired outdoor
          area which includes a main pool as well lounging spaces to make the
          most of the island's pleasant climate.
        </p>
      </div>
    </div>
  );
}
