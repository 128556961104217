import { useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";

const navigation = [
  { name: "Concept", href: "#concept" },
  { name: "Gallery", href: "#gallery" },
  { name: "Features", href: "#features" },
  { name: "Contact", href: "#contact" },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-black">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <p className="text-white font-playfair text-lg uppercase">
                  DG&Associates
                </p>
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div className="relative isolate overflow-hidden">
        <img
          src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/2462ac163877775.63ed2c26b80a4.jpg"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover lg:object-[center_-250px]"
        />
        <div className="backdrop-brightness-50">
          <p className="font-playfair text-sm uppercase text-white text-center pt-10">
            DG&Associates
          </p>
          <div className="mx-auto max-w-2xl py-48">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight text-white sm:text-6xl font-playfair font-regular italic">
                Villa Sierra
              </h1>
              <p className="mt-6 px-6 text-lg leading-base text-white font-bricolage">
                Nestled on the sunny coast of Bali, this tropical chic villa
                blends modern sophistication and natural materials, creating the
                most peaceful and intimate atmosphere
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6 font-bricolage font-medium">
                <a
                  href="#contact"
                  className=" border-transparent border bg-white px-3.5 py-2.5 text-sm text-black shadow-sm hover:border hover:border-white hover:bg-transparent hover:text-white focus:none outline-none duration-200"
                >
                  Contact us
                </a>
                <div className="flex items-center gap-2 hover:translate-x-1 duration-200">
                  <a
                    href="#concept"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    Learn more
                  </a>
                  <ArrowRightIcon className="w-4 h-4 text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav
        className="hidden md:flex md:gap-x-8 items-center justify-center p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex md:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-sm font-semibold tracking-wide uppercase leading-6 font-bricolage text-white duration-200 hover:border-white hover:border-b border-b border-black"
            >
              {item.name}
            </a>
          ))}
        </div>
      </nav>
    </div>
  );
}
