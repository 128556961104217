import { Swiper, SwiperSlide } from "swiper/react";
import { useBreakpoint } from "../lib/breakpoints";

import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/autoplay";
import "swiper/css/navigation";

export default function Carousel() {
  const interiors = [
    {
      name: "Living room",
      imageUrl:
        "https://img.jamesedition.com/listing_images/2022/05/06/11/08/47/c3a1567e-e7b1-413f-8212-5c08deebba26/je/2200xxs.jpg",
    },
    {
      name: "Dining room",
      imageUrl:
        "https://img.jamesedition.com/listing_images/2022/05/06/11/08/47/4b724837-9f1a-4b18-a3bc-a4dae1b198c4/je/2200xxs.jpg",
    },
    {
      name: "Master Bedroom",
      imageUrl:
        "https://img.jamesedition.com/listing_images/2022/05/06/11/08/47/2b5a58d1-197d-45a2-a767-a06bffc780fb/je/2200xxs.jpg",
    },
    {
      name: "Outdoor Bathroom",
      imageUrl:
        "https://img.jamesedition.com/listing_images/2022/05/06/11/08/47/76894cda-076a-4fb1-914b-b23a792e8886/je/2200xxs.jpg",
    },
    {
      name: "His & Hers Showers",
      imageUrl:
        "https://content.knightfrank.com/property/rsi012248020/images/7a09a705-324e-4933-8783-fbf530945e24-0.jpg",
    },
    {
      name: "Secondary Bedroom",
      imageUrl:
        "https://content.knightfrank.com/property/rsi012248020/images/971ad832-6e15-433c-a11a-3480418a656d-0.jpg",
    },
    {
      name: "Outdoor pool",
      imageUrl:
        "https://content.knightfrank.com/property/rsi012248020/images/c360771a-2ede-4f4d-a1dc-d3e78e7d7348-0.jpg",
    },
    {
      name: "Outdoor Living",
      imageUrl:
        "https://img.jamesedition.com/listing_images/2022/05/06/11/08/47/25cf9c97-77db-4058-9ffd-334375414ac2/je/2200xxs.jpg",
    },
  ];

  const breakpoint = useBreakpoint();
  const slidesPerView = {
    xs: 1.5,
    sm: 2,
    md: 2.5,
    lg: 2.5,
    xl: 3,
  };

  return (
    <div className="overflow-hidden">
      <div
        id="gallery"
        className="slider mx-auto max-w-5xl md:py-32 py-12 px-8"
      >
        <h2 className="uppercase font-bold font-bricolage mb-8">Interiors</h2>
        <div className="overflow-visible">
          <Swiper
            spaceBetween={20}
            slidesPerView={slidesPerView[breakpoint]}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {interiors.map((item, index) => (
              <div className="">
                <SwiperSlide key={index}>
                  <img
                    className="object-cover flex-shrink-0 w-full h-full"
                    src={item.imageUrl}
                    alt=""
                  />
                  <p className="mt-4 font-playfair italic">{item.name}</p>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
