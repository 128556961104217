import { useState } from "react";
import { Tab } from "@headlessui/react";
import {
  BookOpenIcon,
  EyeIcon,
  FilmIcon,
  HeartIcon,
  MusicalNoteIcon,
  SparklesIcon,
  StarIcon,
  SunIcon,
} from "@heroicons/react/24/outline";

import {
  FitnessCenterOutlined,
  FreeBreakfastOutlined,
  LocalBarOutlined,
  StorefrontOutlined,
} from "@mui/icons-material";

export default function Tabs() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const features = [
    { feature: "bedrooms", info: "3" },
    { feature: "bathrooms", info: "4" },
    { feature: "pool", info: "12m2" },
    { feature: "sauna", info: "4pax" },
    { feature: "gym", info: "fully equipped" },
    { feature: "view", info: "mountain" },
    { feature: "restaurants", info: "2" },
    { feature: "clubhouse", info: "residents & guests" },
    { feature: "concierge", info: "24h" },
  ];

  const lifestyle = [
    {
      category: "Culture",
      content: [
        {
          icon: EyeIcon,
          title: "See",
          description: "2+ museums / galleries within 1 / 2 mile",
        },
        {
          icon: MusicalNoteIcon,
          title: "Hear",
          description: "2+ music / performance /dance venues within 1 mile",
        },
        {
          icon: BookOpenIcon,
          title: "Read",
          description: "1+ bookstore within 1 mile",
        },
        {
          icon: FilmIcon,
          title: "Watch",
          description: "2+ live theaters/cinemas within 1 mile",
        },
      ],
    },
    {
      category: "Wellness",
      content: [
        {
          icon: SunIcon,
          title: "Nature",
          description: "1 park within 1 mile",
        },
        {
          icon: FitnessCenterOutlined,
          title: "Workout",
          description: "3+ gyms / class-training studios within 1/2 mile",
        },
        {
          icon: SparklesIcon,
          title: "Relax",
          description: "2+ spas/yoga studios within 1/2 mile",
        },
        {
          icon: HeartIcon,
          title: "Nourish",
          description: "2+ juice bars/health food boutiques within 1/2 mile",
        },
      ],
    },
    {
      category: "Culinary",
      content: [
        {
          icon: StarIcon,
          title: "Starred",
          description: "2+ Michelin-rated eateries within 1 mile",
        },
        {
          icon: FreeBreakfastOutlined,
          title: "Caffeine",
          description: "5+ coffee shops withing 1 mile",
        },
        {
          icon: LocalBarOutlined,
          title: "Eateries",
          description: "6+ al fresco eateries within 1 mile",
        },
        {
          icon: StorefrontOutlined,
          title: "Market",
          description: "3+ popular grocers within 1 mile",
        },
      ],
    },
  ];

  return (
    <div id="features" className="max-w-5xl mx-auto bg-[#588157]">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="grid-cols-2 grid gap-10 max-w-md mx-auto py-10">
          <Tab className="ui-selected:border-b-2 ui-selected:text-[#CEDECE] ui-selected:border-b-[#CEDECE] ui-not-selected:border-b-2 ui-not-selected:border-b-black outline-none pb-4 duration-200 font-bricolage">
            Amenities
          </Tab>
          <Tab className="ui-selected:border-b-2 ui-selected:border-b-[#CEDECE] ui-selected:text-[#CEDECE] ui-not-selected:border-b-2 ui-not-selected:border-b-black duration-200 outline-none pb-4">
            Location
          </Tab>
        </Tab.List>
        <Tab.Panels className="px-8 py-10">
          <Tab.Panel>
            <div className="grid md:grid-cols-3 md:gap-x-12 gap-y-10 text-[#CEDECE]">
              {features.map((item) => (
                <div className="flex justify-between pb-4 border-b border-[#CEDECE] uppercase font-bricolage text-xs">
                  <p>{item.feature}</p>
                  <p>{item.info}</p>
                </div>
              ))}
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div>
              {lifestyle.map((item, index) => {
                return (
                  <div className="flex flex-col mb-10">
                    <h2 className="pb-4 text-lg font-semibold italic font-playfair tracking-wide text-[#CEDECE]">
                      {item.category}
                    </h2>
                    <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-y-10 gap-x-10 text-[#CEDECE]">
                      {item.content.map((itm, idx) => (
                        <div className="flex flex-col gap-y-2">
                          <itm.icon className="w-6" />
                          <h3 className="text-md font-bricolage font-medium">
                            {itm.title}
                          </h3>
                          <p className="text-sm font-bricolage  ">
                            {itm.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
