export default function Text() {
  return (
    <div className="mx-auto md:max-w-5xl md:py-32 py-12 px-8 md:flex">
      <h2
        id="concept"
        className="md:w-1/2 font-[500] font-bricolage uppercase mb-4 tracking-wide"
      >
        The Concept
      </h2>
      <p className="md:w-1/2 text-left text-black font-bricolage">
        Inspired by the allure of coastal living, the villa's design
        effortlessly blends modern sophistication with the charm of the
        Mediterranean, creating an ambiance of serenity that welcomes its
        residents with open arms. Every detail has been meticulously curated to
        offer the utmost comfort, ensuring that each moment spent within these
        walls is one of pure relaxation and contentment.
      </p>
    </div>
  );
}
