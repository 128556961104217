export default function Featured() {
  const features = [
    { feature: "Metre square", info: "482" },
    { feature: "bedrooms", info: "3" },
    { feature: "bathrooms", info: "4" },
  ];

  return (
    <div className="bg-black">
      <div className="mx-auto md:max-w-5xl md:py-32 py-12 px-8 md:grid grid-cols-2 gap-x-16 space-y-10 md:space-y-0">
        <div className="text-white">
          <h3 className="font-bold font-bricolage tracking-wide uppercase mb-4">
            Intentionally designed
          </h3>
          <p className="font-bricolage">
            The use of a soft, neutral color palette throughout the interior
            spaces invokes a sense of calmness, while strategically placed
            windows and open layouts invite an abundance of natural light,
            further enhancing the soothing atmosphere. From the plush
            furnishings that embrace you in opulence to the state-of-the-art
            amenities that cater to your every need, our villa is a haven of
            indulgence and refinement, promising an unforgettable experience
            that will linger in your heart long after your stay.
          </p>
          <div className="grid grid-cols-3 mt-8 md:place-items-start">
            {features.map((item) => (
              <div>
                <p className="text-center font-bricolage uppercase text-xs">
                  <span className=" text-2xl">{item.info}</span>
                  <br />
                  {item.feature}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="">
          <img
            src="https://img.jamesedition.com/listing_images/2022/05/06/11/08/47/25cf9c97-77db-4058-9ffd-334375414ac2/je/2200xxs.jpg"
            className="object-cover"
          />
        </div>
      </div>
    </div>
  );
}
