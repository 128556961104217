import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import {
  PhoneIcon,
  MapPinIcon,
  AtSymbolIcon,
} from "@heroicons/react/24/outline";

export default function Contact() {
  const info = [
    { icon: MapPinIcon, info: "Carnaby Street, London" },
    { icon: PhoneIcon, info: "+44 555 666 777" },
    { icon: AtSymbolIcon, info: "sales@email.com" },
  ];

  return (
    <div id="contact" className="md:py-32 py-12 bg-black">
      <div className="max-w-5xl mx-auto px-8">
        <h2 className="uppercase font-semibold font-bricolage tracking-wide text-white">
          Contact us
        </h2>
        <p className="text-white font-bricolage pt-2">
          Interested in this property? Let's get in touch!
        </p>
        <form action="#" method="POST" className="mt-16">
          <div className="grid sm:grid-cols-2 gap-x-16 gap-y-6">
            <div className="flex flex-col gap-y-8">
              <div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  autoComplete="given-name"
                  className="block w-full border-b bg-black border-white px-3.5 py-2 text-white placeholder:text-white ring-0 outline-0 sm:text-sm  sm:leading-6 font-bricolage"
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  autoComplete="email"
                  className="block w-full border-b border-white bg-black px-3.5 py-2 text-white placeholder:text-white outline-0  focus:text-black  sm:text-sm sm:leading-6 font-bricolage"
                />
              </div>
              <div>
                <input
                  type="tel"
                  name="phone-number"
                  id="phone-number"
                  autoComplete="tel"
                  placeholder="Phone"
                  className="block w-full border-b px-3.5 py-2 bg-black border-white text-white placeholder:text-white outline-0 sm:text-sm sm:leading-6 font-bricolage"
                />
              </div>
            </div>
            <div className="flex flex-col justify-between	h-full gap-y-8">
              <div>
                <textarea
                  name="message"
                  id="message"
                  rows={1}
                  className="block w-full px-3.5 py-2 border-b bg-black border-white text-gray-900  placeholder:text-white outline-0 sm:text-sm sm:leading-6 font-bricolage"
                  placeholder="Message"
                  defaultValue={""}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="block border-white border px-3.5 py-2.5 text-center text-sm font-semibold text-white hover:text-black duration-200 hover:bg-white font-bricolage"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="pt-20 grid md:grid-cols-3 gap-y-5">
          {info.map((item) => (
            <div className="text-white flex items-center gap-3">
              <item.icon className="w-5 h-5" />
              <p className="text-sm font-bricolage">{item.info}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
